
import { gpf } from "@/utils/global-functions";
import { popupStatus } from "@/utils/popup/status";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  nextTick,
  reactive,
  watch,
} from "vue";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const TextField = defineAsyncComponent(
  () => import(`@/global-components/input/text-field/Main.vue`)
);

const SingleImageUpload = defineAsyncComponent(
  () => import(`@/global-components/input/single-image-upload/Main.vue`)
);

const RadioGroup = defineAsyncComponent(
  () => import(`@/global-components/input/radio-group/Main.vue`)
);

const servicesV1AdminLearningCategories = async () =>
  import(`@/services/v1/admin/learning/categories`);

export default defineComponent({
  props: {
    showFormCategory: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: Number,
      default: 0,
    },
    categoryCode: {
      type: String,
      default: "",
    },
  },
  components: {
    ModalTemplate,
    TextField,
    SingleImageUpload,
    RadioGroup,
  },
  setup(props, { emit }) {
    // START === Constant variable ===============================================
    const modalId = "modal-form-category";
    const categoryId = computed(() => props.categoryId);
    const categoryCode = computed(() => props.categoryCode);
    const isEditPage = computed(() => !!categoryCode.value);

    const titleForm = computed(() =>
      isEditPage.value ? "Form Edit Kategori" : "Form Add Kategori"
    );

    const listStatus = [
      {
        text: "Active",
        value: "1",
      },
      {
        text: "Inactive",
        value: "0",
      },
    ];

    // END === Constant variable =================================================

    // START === for handle show hide modal form category ====================================
    const hideFormCategory = () => {
      resetForm();

      emit("update:show-form-category", false);
      emit("update:category-id", 0);
      emit("update:category-code", "");
    };

    watch(
      () => props.showFormCategory,
      (newVal) => {
        console.log("props.showFormCategory newVal ", props);

        if (newVal) gpf.showModal(`#${modalId}`);
        gpf.onHideModal(`#${modalId}`, hideFormCategory);
      }
    );
    // END === for handle show hide modal form category ======================================

    // START === Form Data =================================================================
    const form = reactive({
      kode: "",
      title: "",
      banner: {
        file: "",
        fileUrl: "",
      },
      isActive: "0",
    });

    const resetForm = () => {
      form.kode = "";
      form.title = "";
      form.banner = {
        file: "",
        fileUrl: "",
      };
      form.isActive = "0";
    };

    // END === Form Data =================================================================

    // START === Functions handle get detail category ==========================================
    const getDetailCategory = async () => {
      const {
        getAdminLearningCategoryById,
      } = await servicesV1AdminLearningCategories();

      gpf.gLoading.show();

      try {
        const hit = await getAdminLearningCategoryById(categoryCode.value);

        await nextTick();

        const resData = hit.response;
        console.log("resData getDetailCategory ", resData);

        form.kode = resData.kode;
        form.title = resData.title;
        form.banner.fileUrl = resData.banner;
        form.isActive = resData.isActive ? "1" : "0";

        console.log("form ", form);
      } catch (err: any) {
        console.log("err response getDetailCategory ", err, err?.response);

        gpf.handleEds(err);
      } finally {
        gpf.gLoading.hide();
      }
    };

    watch(
      () => isEditPage.value,
      (newVal) => {
        console.log("newVal isEditPage.value ", newVal);

        if (newVal) getDetailCategory();
      }
    );
    // END === Functions handle get detail category ============================================

    // START === Function actions =========================================================
    const onCancelClick = () => gpf.hideModal(`#${modalId}`);

    const onSaveClick = () => {
      console.log("category ID ", categoryId.value);
      console.log("category Slug ", categoryCode.value);

      if (isEditPage.value) {
        hitEditCategory();
      } else {
        hitAddCategory();
      }
    };

    const afterClickSaveCategory = () => {
      gpf.hideModal(`#${modalId}`);
      emit("update:refetch", true);
    };

    const hitAddCategory = async () => {
      const {
        postAdminLearningCategory,
      } = await servicesV1AdminLearningCategories();

      gpf.gLoading.show();

      const body = new FormData();
      body.append("kode", form.kode);
      body.append("title", form.title);
      body.append("banner", form.banner.file);
      body.append("isActive", form.isActive === "1" ? "1" : "0");

      try {
        await postAdminLearningCategory(body);

        popupStatus({
          type: "s",
          message: "Successfully Created Kategori",
        }).show();

        afterClickSaveCategory();
      } catch (err: any) {
        console.error("err hitAddCategory ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();
      }
    };

    const hitEditCategory = async () => {
      const {
        putAdminLearningCategory,
      } = await servicesV1AdminLearningCategories();

      gpf.gLoading.show();

      const body = new FormData();
      body.append("kode", form.kode);
      body.append("title", form.title);

      const fileBanner = form.banner.file;
      if (fileBanner) {
        body.append("banner", fileBanner);
      } else {
        body.append("banner", "");
      }

      body.append("isActive", form.isActive === "1" ? "1" : "0");

      try {
        await putAdminLearningCategory(categoryCode.value, body);

        popupStatus({
          type: "s",
          message: "Successfully Updated Kategori",
        }).show();

        afterClickSaveCategory();
      } catch (err: any) {
        console.error("err hitEditCategory ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();
      }
    };
    // END === Function actions ===========================================================

    return {
      modalId,
      isEditPage,
      titleForm,
      listStatus,
      form,

      onCancelClick,
      onSaveClick,
    };
  },
});
